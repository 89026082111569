import { http } from '@/api';

//获取闭关修炼列表接口
export function getcultivatingcollections(params) {
  return http.get('/v2/regular/getcultivatingcollections', params);
}

//获取派遣列表接口
export function getwaitregularList(params) {
  return http.get('/v2/regular/getwaitregularList', params);
}

//批量终止修炼接口
export function stopregular(params) {
  return http.post('/v2/regular/stopregular', params);
}

//批量修炼接口信息
export function collectionregular(params) {
  return http.post('/v2/regular/collectionregular', params);
}

//修炼配置信息接口
export function regularconfig(params) {
  return http.get('/v2/regular/regularconfig', params);
}

//批量领取积分接口
export function receivescores(params) {
  return http.post('/v2/regular/receivescores', params);
}

export function getstopregularlist(params) {
  return http.get('/v2/regular/getstopregularlist', params);
}

// 组合修炼派遣列表
export function getcomwaitregularList(params) {
  return http.get('/v2/regular/getcomwaitregularList', params);
}

// 组合闭关修炼列表接口
export function getcomcultivatingcollections(params) {
  return http.get('/v2/regular/getcomcultivatingcollections', params);
}

// 组合终止修炼接口列表
export function getcomstopregularlist(params) {
  return http.get('/v2/regular/getcomstopregularlist', params);
}

// 组合修炼接口信息
export function comcollectionregular(params) {
  return http.post('/v2/regular/comcollectionregular', params);
}

// 批量终止修炼接口
export function comstopregular(params) {
  return http.post('/v2/regular/comstopregular', params);
}